import { ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { ApolloClientOptions, ApolloQueryResult } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { Observable, OperatorFunction, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AUTH_TYPE } from 'aws-appsync';
import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link';

import { untilDestroyed } from '@s2a-core/ng-core';
import { LOCALSTORAGE_AUTH_TOKEN } from '@s2a/core';

import { createSubscriptionHandshakeLink } from '../../../../../docs/aws-appsync-subscription-link/lib';
import { environment } from '../../environments/environment';
import { bulkHistoryUpdateMutation, historyUpdateMutation } from '../graphql/mutations/history-item.mutation';
import { getHistories, LastEvaluatedKey, unseenCountQuery } from '../graphql/queries/history-item.queries';
import { ReadStatus } from '../models/notification-history.model';

@Injectable({
  providedIn: 'root',
})
export class GraphQLHistoryService {
  client$ = new Subject<any>();
  private untilDestroyed = untilDestroyed();

  constructor(private apolloClient: Apollo) {}

  setupGraphClient(isValidSession: boolean): void {
    if (isValidSession) {
      this.removeClient();
      const client: ApolloClientOptions<unknown> = this.createClient();
      client.cache = new InMemoryCache();
      this.apolloClient.create(client);
      this.client$.next(client);
    } else {
      this.removeClient();
    }
  }

  removeClient(): void {
    if (this.apolloClient.client) {
      this.apolloClient.removeClient();
    }
  }

  updateHistory(timestamp: number, readStatus: ReadStatus): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.apolloClient.mutate({
      mutation: historyUpdateMutation(timestamp, readStatus),
    });
  }

  bulkHistoryUpdate(readStatus: ReadStatus): Observable<any> {
    return this.apolloClient.mutate({
      mutation: bulkHistoryUpdateMutation(readStatus),
    });
  }

  getUnseenCount(): Observable<number> {
    return this.client$.pipe(
      mergeMap(() =>
        this.apolloClient
          .query({
            query: unseenCountQuery,
          })
          .pipe(
            this.untilDestroyed(),
            map(
              (response: any) => response.data.getUnseenCount.unseenCount as OperatorFunction<any, any>,
            ) as OperatorFunction<ApolloQueryResult<unknown>, unknown>,
          ),
      ),
    ) as Observable<number>;
  }

  getHistory(pageSize: number, lastEvaluatedKey?: LastEvaluatedKey): Observable<any> {
    return this.apolloClient
      .query({
        query: getHistories(pageSize, lastEvaluatedKey),
      })
      .pipe(
        this.untilDestroyed(),
        map((responseData: any) => responseData.data.getHistory as OperatorFunction<unknown, any>) as OperatorFunction<
          unknown,
          any
        >,
      );
  }

  // private createClient(): any {
  //   return new AWSAppSyncClient<any>({
  //     disableOffline: true,
  //     url: environment.notificationHistoryUrl ?? 'not set',
  //     region: 'cn-northwest-1',
  //     auth: {
  //       type: AUTH_TYPE.AWS_LAMBDA,
  //       token: `Bearer ${localStorage.getItem(LOCALSTORAGE_AUTH_TOKEN) ?? 'not set'}`,
  //     },
  //   });
  // }
  private createClient(): any {
    const lambdaAuth: AuthOptions = {
      type: AUTH_TYPE.AWS_LAMBDA,
      token: `Bearer ${localStorage.getItem(LOCALSTORAGE_AUTH_TOKEN) ?? 'not set'}`,
    };
    const httpLink = createHttpLink({
      uri: environment.notificationHistoryUrl,
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const link = ApolloLink.from([
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      createAuthLink({ url: environment.notificationHistoryUrl, region: 'cn-northwest-1', auth: lambdaAuth }),
      createSubscriptionHandshakeLink(
        {
          url: environment.notificationHistoryUrl,
          region: 'cn-northwest-1',
          auth: lambdaAuth,
          keepAliveTimeoutMs: 24 * 60 * 60 * 1000,
        },
        httpLink,
      ),
    ]);

    const options = {
      link,
      cache: new InMemoryCache(),
    };

    return options;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    // return new this.apolloClient.create({
    //   link,
    //   cache: new InMemoryCache(),
    // });
  }
}
