import { Component, Input } from '@angular/core';

@Component({
  selector: 's2a-pure-filter-panel',
  templateUrl: './pure-filter-panel.component.html',
  styleUrls: ['./pure-filter-panel.component.scss'],
})
export class PureFilterPanelComponent {
  /**
   * The title of the panel
   */
  @Input() title = '';

  /**
   * Display mode.
   *
   * Can be `expanded` or `closed`
   *
   */
  @Input() displayMode: 'expanded' | 'closed' = 'expanded';
}
