const ESHOP_URL = 'https://shop.krones.com/shop';
const ESHOP_URL_DE = 'https://shop.krones.com/shop/de/de';
const ESHOP_URL_US = 'https://shop.krones.com/shop/us/en';

export const getKroneEshopUrl = (languageKey: string) => {
  switch (languageKey) {
    case 'de':
      return ESHOP_URL_DE;
    case 'en':
      return ESHOP_URL_US;
    default:
      return ESHOP_URL;
  }
};
