import { map } from 'rxjs/operators';

import { Component } from '@angular/core';

import { untilDestroyed } from '@s2a-core/ng-core';
import { AuthService } from '@s2a/core';

import { APPBELL_RIGHT } from '../../config/history.config';
import {
  FilterOptions,
  HistoryPageSize,
  ReadStatus,
  Timeline,
  TimelineEvent,
  TimelineGroup,
} from '../../models/notification-history.model';
import { HistoryItemService } from '../../services/history-item.service';
import { RouterService } from '../../services/router.service';

@Component({
  selector: 's2a-notification-timeline',
  templateUrl: './notification-timeline.component.html',
  styleUrls: ['./notification-timeline.component.scss'],
})
export class NotificationTimelineComponent {
  valueRetrieved = false;
  loadingPaginatedData = false;
  _timelineGroups: TimelineGroup[];
  histories: Timeline[] = [];
  private untilDestroyed = untilDestroyed();

  constructor(
    private historyItemService: HistoryItemService,
    private authService: AuthService,
    private routerService: RouterService,
  ) {
    this.establishHistorySubscription();
  }

  get timelineGroups(): TimelineGroup[] {
    return this._timelineGroups;
  }

  set timelineGroups(timelineGroups: TimelineGroup[]) {
    this._timelineGroups = timelineGroups;
  }

  onReadAllNotification(): void {
    this.historyItemService.updateUnreadHistories();
  }

  onFilterTimeline(value: ReadStatus): void {
    let histories = this.histories;
    if (value !== ReadStatus.ALL) {
      histories = this.histories.filter((history: Timeline) => history.readStatus === value);
    }
    this.timelineGroups = this.historyItemService.groupHistoryItem(histories);
  }

  onTimelineClicked(event: TimelineEvent): void {
    this.onStatusUpdate(event);
    this.openLink(event.appLink);
  }

  onStatusUpdate(event: TimelineEvent): void {
    if (event.readStatus === ReadStatus.UNREAD) {
      this.historyItemService.updateStatus(event.timestamp, ReadStatus.READ);
    }
  }

  onSwitchReadStatus(event: TimelineEvent): void {
    if (event.readStatus === ReadStatus.READ) {
      // only set to unread if status was read before
      this.historyItemService.updateStatus(event.timestamp, ReadStatus.UNREAD);
    } else {
      // if the read status is unseen / seen / unread
      // we are allowed to set the readStatus to read
      this.historyItemService.updateStatus(event.timestamp, ReadStatus.READ);
    }
  }

  onApplyFilter(filterOptions: FilterOptions): void {
    if (!filterOptions.services && !filterOptions.date) {
      this.timelineGroups = this.historyItemService.groupHistoryItem(this.histories);

      return;
    }

    let histories = this.histories;
    if (filterOptions.services) {
      histories = this.histories.filter((history) => filterOptions.services.includes(history.service));
    }

    if (filterOptions.date) {
      histories = this.histories.filter(
        (history) => history.timestamp >= filterOptions.date.from && history.timestamp <= filterOptions.date.to,
      );
    }
    this.timelineGroups = this.historyItemService.groupHistoryItem(histories);
  }

  fetchMore(): void {
    if (!this.historyItemService.lastEvaluatedKey) {
      return;
    }
    this.loadingPaginatedData = true;
    this.historyItemService.getAllRecordByPaging(HistoryPageSize, this.historyItemService.lastEvaluatedKey);
  }

  private openLink(link: string | undefined): void {
    // only handle valid links
    if (!link) {
      return;
    }

    this.routerService.redirectToApp(link);
  }

  private establishHistorySubscription(): void {
    if (this.authService.hasGlobalRight(APPBELL_RIGHT)) {
      this.historyItemService.establishGetHistorySubscription(HistoryPageSize);
      this.historyItemService.historyItemsRetrieved$.pipe(this.untilDestroyed()).subscribe((value) => {
        this.valueRetrieved = value;
        this.loadingPaginatedData = false;
      });
      this.historyItemService.historyItems$
        .pipe(
          this.untilDestroyed(),
          map((histories: Timeline[]) => {
            this.histories = histories;

            return this.historyItemService.groupHistoryItem(histories);
          }),
        )
        .subscribe((data: TimelineGroup[]) => {
          this.timelineGroups = data;
        });
    }
  }
}
