<div class="filter-wrapper" s2aLayout="column" s2aLayoutAlign="space-between start">
  <div class="title-bar">
    <div s2aLayout="row" s2aLayoutAlign="space-between start">
      <div translate class="text" s2aFlex>global.filter</div>
      <button mat-icon-button color="accent" (click)="onCancelClicked()">
        <mat-icon svgIcon="cancel"></mat-icon>
      </button>
    </div>
  </div>

  <s2a-pure-filter-panel class="expansion-panel" [title]="'components.notification.service' | translate">
    <div ngProjectAs="content" class="expansion-content">
      <mat-chip-listbox [multiple]="true">
        <mat-chip-option
          *ngFor="let service of allServices"
          [selected]="isSelectedService(service.key)"
          (click)="onSelectService(service.key)"
        >
          {{ service.value | translate }}
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
  </s2a-pure-filter-panel>

  <s2a-pure-filter-panel class="expansion-panel" [title]="'global.time_labels.date' | translate">
    <div ngProjectAs="content" class="expansion-content">
      <mat-chip-listbox [multiple]="false">
        <mat-chip-option
          *ngFor="let options of dateOptions"
          [selected]="isSelectedDateOption(options.key)"
          (click)="onSelectDateOption(options.key)"
        >
          {{ options.value | translate }}
        </mat-chip-option>
      </mat-chip-listbox>
      <ng-container *ngIf="selectedDateOption === 'custom'">
        <div class="date-picker-wrapper">
          <s2a-wc-date-range-picker
            [label]="'global.time_labels.date' | translate"
            [placeholder]="'global.select' | translate"
            [maxDate]="today"
            [dateRange]="dateRange"
            (dateRangeChange)="onSelectDateRange($event)"
          >
          </s2a-wc-date-range-picker>
        </div>
      </ng-container>
    </div>
  </s2a-pure-filter-panel>

  <div class="footer" s2aLayout="column" s2aLayoutAlign="space-between center">
    <button class="reset-filter-button" mat-button color="accent" (click)="onResetFilter()">
      <div s2aLayout="row" s2aLayoutAlign="center center">
        <mat-icon svgIcon="backToStart"></mat-icon>
        <p translate>global.reset_all_filters</p>
      </div>
    </button>
    <button class="apply-filter-button" mat-flat-button color="primary" (click)="onApplyFilter()">
      <p translate>global.apply</p>
    </button>
  </div>
</div>
