<ng-container *ngIf="!showFilter; else filter">
  <s2a-pure-timeline-header
    [filterApplied]="isFilterApplied"
    (toggledFilter)="onToggledFilter($event)"
    (filterClicked)="toggleFilter()"
    (readAllNotification)="onReadAllNotification()"
  ></s2a-pure-timeline-header>

  <ng-container *ngIf="valueRetrieved; else loadingWrapper">
    <ng-container *ngIf="timelineGroups?.length; else noRecordWrapper">
      <s2a-virtual-history-item
        [timelineGroups]="timelineGroups"
        (fetchPaginatedData)="fetchMore()"
        (switchReadStatus)="onSwitchReadStatus($event)"
        (timelineClicked)="onTimelineClick($event)"
        [loadingPaginatedData]="loadingPaginatedData"
      >
      </s2a-virtual-history-item>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #filter>
  <s2a-pure-timeline-filter
    [filterOptions]="filterOptions"
    (cancelClicked)="toggleFilter()"
    (applyFilterClicked)="onApplyFilter($event)"
  ></s2a-pure-timeline-filter>
</ng-template>

<ng-template #noRecordWrapper>
  <div class="no-record" s2aLayout="row" s2aLayoutAlign="center center">
    <div translate>components.notification.no_more_notifications</div>
  </div>
</ng-template>

<ng-template #loadingWrapper>
  <div class="timeline skeleton"></div>
  <div class="timeline skeleton"></div>
  <div class="timeline skeleton"></div>
  <div class="timeline skeleton"></div>
  <div class="timeline skeleton"></div>
</ng-template>
