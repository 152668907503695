import { Component, Input } from '@angular/core';

@Component({
  selector: 's2a-pure-history-divider',
  templateUrl: './pure-history-divider.component.html',
  styleUrls: ['./pure-history-divider.component.scss'],
})
export class PureHistoryDividerComponent {
  /**
   * The separator text
   *
   * @required
   */
  @Input() separatorText = '';
}
