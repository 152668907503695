import { JwtHelperService } from '@auth0/angular-jwt';
import { tap } from 'rxjs/operators';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AuthService, LocalStorageService, LOCALSTORAGE_AUTH_TOKEN } from '@s2a/core';

import { CookieService, S2A_LAST_USED_APP, SessionService } from '~ng-shell';

import { AppModule } from './app/app.module';
import { User } from './app/models/user.model';
import { RouterService } from './app/services/router.service';
import { environment } from './environments/environment';

interface AuthResponse {
  TokenType: 'Bearer';
  IdToken: string;
  AccessToken: string;
  ExpiresIn: number;
  RefreshToken: string;
  token: string;
}

if (environment.production) {
  enableProdMode();
}

// App Bootstrapping
const bootstrapApp = () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
};

// Api call
const getCall = async (apiUrl: string, token: string) =>
  fetch(apiUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

const postCall = async (apiUrl: string, userCred: { code: string; accountId: string }) =>
  fetch(apiUrl, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(userCred),
  });

// Api call to get User's info and rights
const userInfo = async (token: string, userId: string): Promise<User & { globalRights: string[] }> => {
  const userPromise = getCall(`${environment.apiUrl}users/${userId}`, token);
  const rightsPromise = getCall(`${environment.apiUrl}users/me/global-rights`, token);

  const [userResultResponse, rightsResultResponse] = await Promise.all([userPromise, rightsPromise]);

  const user = (await userResultResponse.json()) as User & {
    username?: string;
  };
  const rights = (await rightsResultResponse.json()) as string[];

  return { ...user, login: user.username ?? 'not set', globalRights: rights };
};

const authInfo = async (userCred) => {
  const authResult = await postCall(
    `${environment.apiUrl}auth/tokens`,
    userCred as { code: string; accountId: string },
  );

  const authInformation: any = (await authResult.json()) as AuthResponse;
  let userId = authInformation.userId;
  if (authInformation.AccessToken) {
    const jwt = new JwtHelperService();
    userId = jwt.decodeToken(authInformation.IdToken as string)['custom:userId'];
  }
  const userDetails = await userInfo(authInformation.token as string, userId as string);

  return {
    ...userDetails,
    ...{
      IdToken: authInformation.token,
      RefreshToken: authInformation.RefreshToken,
      AccessToken: authInformation.AccessToken,
    },
  };
};

// Check if there is any Router Fragment for Authentication Setup
const routeFragment = new URLSearchParams(location.hash.slice(1));
const routeParams = new URLSearchParams(location.search.slice(1));

if (routeFragment.has('idToken') && routeFragment.has('accessToken') && routeFragment.has('refreshToken')) {
  const sessionService = new SessionService();
  const token = routeFragment.get('idToken') ?? 'not set';
  const jwt = new JwtHelperService();
  const userId = jwt.decodeToken(token)['custom:userId'] as string;
  void userInfo(token, userId).then((data) => {
    sessionService.setSession({
      ...data,
      ...{
        IdToken: token,
        AccessToken: routeFragment.get('accessToken'),
        RefreshToken: routeFragment.get('refreshToken'),
      },
    });
    location.hash = '';
    bootstrapApp();
  });
} else if (routeParams.has('code') && routeParams.has('accountId')) {
  void authInfo({
    code: routeParams.get('code'),
    accountId: routeParams.get('accountId'),
  }).then((data) => {
    const sessionService = new SessionService();
    sessionService.setSession(data);
    location.search = '';
    bootstrapApp();
  });
} else if (routeParams.has('accountId')) {
  const accountId = routeParams.get('accountId');
  const jwt = new JwtHelperService();
  const storage = new LocalStorageService();
  const routerService = new RouterService(window);
  if (storage.getItem(LOCALSTORAGE_AUTH_TOKEN)) {
    const tokenAccountId = jwt.decodeToken(storage.getItem(LOCALSTORAGE_AUTH_TOKEN))['custom:accountId'] as string;
    if (tokenAccountId === accountId) {
      bootstrapApp();
    } else {
      const authService = new AuthService(environment);
      const sessionService = new SessionService();
      const cookieService = new CookieService(document);
      authService.isLoggedIn$
        .pipe(
          tap((isLogin: boolean) => {
            if (!isLogin) {
              sessionService.clearSession();
              // Need to remove accountId from search string as we will end with two search string..one for the redirect and other for accountId
              const replacedUrl = window.location.search.replace(`accountId=${accountId}`, '');
              const path = window.location.pathname.substring(1); // because of the leading / slash
              // replace the existing state so that a new history is not pushed
              window.history.replaceState(null, '', environment.domainUrl.concat(...[path, replacedUrl]));
              routerService.redirectToLogin();
            }
          }),
        )
        .subscribe();

      cookieService.delete(S2A_LAST_USED_APP);
      authService.logout();
    }
  } else {
    bootstrapApp();
  }
} else {
  bootstrapApp();
}
