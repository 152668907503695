import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ReadStatus, TimelineEvent } from '../../../../models/notification-history.model';

@Component({
  selector: 's2a-pure-history-item',
  templateUrl: './pure-history-item.component.html',
  styleUrls: ['./pure-history-item.component.scss'],
})
export class PureHistoryItemComponent {
  /**
   * Title of the history time
   *
   * @required
   */
  @Input() title = '';

  /**
   * Detail description of the history time
   *
   * @required
   */
  @Input() description = '';

  /**
   * Url to the service image icon
   *
   * @required
   */
  @Input() iconUrl = '';

  /**
   * The service that the history time belongs to
   *
   * @required
   */
  @Input() service = '';

  /**
   * The timestamp for the history item creation
   *
   * @required
   */
  @Input() timestamp: number;

  /**
   * The url that should be opened once clicked on the history item
   *
   * @required
   */
  @Input() titleLink = '';

  /**
   * Indicates the read status
   */
  @Input() isRead = false;

  /**
   * Indicates the load status
   */
  @Input() isLoading = false;

  /**
   * Return the event when a user is clicking on the titleLink
   *
   * @returns string
   */
  @Output() linkClicked = new EventEmitter<TimelineEvent>();

  /**
   * Return the event when a user is clicking "mark as unread" / "mark as read"
   *
   * @returns TimelineEvent
   */
  @Output() switchReadStatus = new EventEmitter<TimelineEvent>();

  /**
   * @ignore
   */
  onLinkClick(): void {
    this.linkClicked.emit(this.getTimelineEvent());
  }

  /**
   * @ignore
   */
  onSwitchReadStatus(): void {
    this.switchReadStatus.emit(this.getTimelineEvent());
  }

  private getTimelineEvent(): TimelineEvent {
    return {
      service: this.service,
      title: this.title,
      timestamp: this.timestamp,
      iconUrl: this.iconUrl,
      description: this.description,
      readStatus: this.isRead ? ReadStatus.READ : ReadStatus.UNREAD,
      appLink: this.titleLink,
    };
  }
}
