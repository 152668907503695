import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '@s2a/core';

import { AppShellApplications } from '../config/application.config';
import { CookieService } from '../services/cookie.service';
import { SessionService } from '../services/session.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeGuard implements CanActivate {
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private sessionService: SessionService,
    private authService: AuthService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean | Observable<boolean> {
    if (this.authService.hasToken) {
      const rights = this.sessionService.getUserInformation().globalRights ?? [];

      const apps = ['welcome'];
      AppShellApplications.forEach((app) => {
        if (rights.some((right: string) => app.enabledByRight.includes(right))) {
          apps.push(app.key);
        }
      });
      if (route.data.appUrl && apps.indexOf(route.data.key as string) === -1) {
        void this.router.navigate(['/forbidden']);

        return false;
      } else {
        this.cookieService.set('s2a-last-used-app', route.data.key as string, 30, '/');
      }

      return true;
    }

    return true;
  }
}
