import { Observable, Subscription } from 'rxjs';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { untilDestroyed } from '@s2a-core/ng-core';

type MatDrawerMode = 'over' | 'push' | 'side';

@Component({
  selector: 's2a-sidenav-skeleton',
  templateUrl: './pure-sidenav-skeleton.component.html',
  styleUrls: ['./pure-sidenav-skeleton.component.scss'],
})
export class PureSidenavSkeletonComponent implements OnInit, OnDestroy {
  /**
   * Is triggered, when profile icon was clicked in mobile layout
   */
  @Output() profileClick = new EventEmitter<void>();

  /**
   * Is triggered, when app bell icon was clicked in mobile layout
   */
  @Output() appBellClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Is used to enable two-way data-binding for 'expanded' attribute
   */
  @Output() expandedChange = new EventEmitter<boolean>();

  /**
   * Is used to detect platform change
   */
  @Output() platformChange = new EventEmitter<boolean>();

  /**
   * If true, the sidenav is expanded, otherwise collapsed.
   */
  @Input() expanded = false;

  /**
   * The base url to the image assets
   */
  @Input() imgBaseUrl: string;

  /**
   * The name of the currently active app. String will be shown in mobile mode in the bottom nav bar next to the menu button.
   */
  @Input() activeAppName: string;

  /**
   * The url to the user's profile image. If not set, the user initials will be shown instead. This will only be shown, if in mobile mode.
   */
  @Input() userImageUrl?: string;

  /**
   * The user's initials. Will be only shown, if the userImageUrl is empty. This will only be shown, if in mobile mode.
   */
  @Input() userInitials: string;

  @Input() itemClickedEvents: Observable<void>;

  /**
   * The boolean that determines if the app bell is supported or not
   */
  @Input() isAppBellSupported: boolean;

  /**
   * The boolean that determines if the app bell is visible or not
   */
  @Input() isAppBellVisible = false;

  /**
   * Badge count
   */
  @Input() badgeCount: number | string = 0;

  /**
   * The boolean that determines if the navigation bar application visible or not
   */
  @Input() isNavigationMenuVisible = true;

  /**
   * @ignore
   */
  sideNavOpened = true;

  /**
   * @ignore
   */
  position: 'start' | 'end' = 'start';

  /**
   * @ignore
   */
  sideNavMode: MatDrawerMode = 'side';

  /**
   * @ignore
   */
  isMobile = false;

  /**
   * @ignore
   */
  extendedHeaderImage: string;

  /**
   * @ignore
   */
  minimizedHeaderImage: string;

  /**
   * @ignore
   */
  private eventsSubscription: Subscription;
  private untilDestroyed = untilDestroyed();

  constructor(private breakpointObserver: BreakpointObserver) {}

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.verifyResponsive();
    this.extendedHeaderImage = `${this.imgBaseUrl}img/brand.svg`;
    this.minimizedHeaderImage = `${this.imgBaseUrl}img/brand-mobile_just_image.svg`;

    if (this.itemClickedEvents) {
      this.eventsSubscription = this.itemClickedEvents.pipe(this.untilDestroyed()).subscribe(() => {
        // Close toggled SideNav in Mobile View
        if (this.isMobile && this.sideNavOpened) {
          this.toggleSidenav();
        }
      });
    }
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.eventsSubscription?.unsubscribe();
  }

  /**
   * @ignore
   */
  toggleSidenavMenu(): void {
    if (!this.isMobile) {
      this.expanded = !this.expanded;
      this.expandedChange.emit(this.expanded);
    } else {
      this.toggleSidenav();
    }
  }

  /**
   * @ignore
   */
  toggleSidenav(): void {
    this.sideNavOpened = !this.sideNavOpened;
  }

  /**
   * @ignore
   */
  onProfileClicked(): void {
    this.profileClick.emit();
  }

  /**
   * @ignore
   */
  onAppBellClicked(): void {
    this.isAppBellVisible = !this.isAppBellVisible;
    this.appBellClick.emit(this.isAppBellVisible);
  }

  /**
   * @ignore
   */
  private verifyResponsive(): void {
    this.breakpointObserver
      .observe(Breakpoints.XSmall)
      .pipe(this.untilDestroyed())
      .subscribe((matchingState) => {
        // If viewpost is ``xs``,
        // show toolbar, else show sidnav
        if (matchingState.matches) {
          this.sideNavMode = 'over';
          this.sideNavOpened = false;
          this.position = 'end';
          this.expanded = true;
          this.isMobile = true;
        } else {
          this.sideNavMode = 'side';
          this.sideNavOpened = true;
          this.position = 'start';
          this.expanded = false;
          this.isMobile = false;
        }
        this.expandedChange.emit(this.expanded);
        this.platformChange.emit(this.isMobile);
      });
  }
}
