import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppShellApplications, ComponentsConfig, SessionService } from '~ng-shell';

import { environment } from '../../environments/environment';
import { AppRegistry } from '../models/app-registry.model';
import { ComponentLifecycleManager } from './component.service';

@Injectable({
  providedIn: 'root',
})
export class PageLoaderService {
  constructor(
    private componentLifecycleManager: ComponentLifecycleManager,
    private httpClient: HttpClient,
    private sessionService: SessionService,
  ) {}

  get userApps(): string[] {
    const rights: string[] = this.sessionService.getUserInformation().globalRights || [];
    const apps: string[] = [];
    AppShellApplications.forEach((app) => {
      if (this.isContain(rights, app.enabledByRight)) {
        apps.push(app.key as string);
      }
    });

    return apps;
  }

  get registeredApps(): AppRegistry[] {
    const rights: string[] = this.sessionService.getUserInformation().globalRights || [];
    const apps: AppRegistry[] = [];
    AppShellApplications.forEach((app) => {
      if (this.isContain(rights, app.enabledByRight)) {
        apps.push({
          id: app.key,
          key: app.key,
          icon: app['icon'] || app.key,
          text: app.text,
          routePath: app.routePath ?? app.key,
        } as AppRegistry);
      }
    });

    return apps;
  }

  get components(): AppRegistry[] {
    const rights: string[] = this.sessionService.getUserInformation().globalRights || [];
    const components: AppRegistry[] = [];
    ComponentsConfig.forEach((app) => {
      if (this.isContain(rights, app.enabledByRight)) {
        components.push({
          id: app.key,
          icon: app.key,
          key: app.key,
        } as AppRegistry);
      }
    });

    return components;
  }

  isContain(firstArray: string[], secondArray: string[]): boolean {
    return firstArray.some((element: string) => secondArray.includes(element));
  }

  render(url: string): void {
    this.componentLifecycleManager.addContent(url);
    this.componentLifecycleManager.attachComponent();
  }

  async isMigratedApp(app: string): Promise<boolean> {
    try {
      await this.httpClient.head(`${environment.domainUrl}apps/${app}`).toPromise();

      return true;
    } catch (err) {
      return false;
    }
  }
}
