export const ComponentsConfig = [
  {
    key: 'app-bell',
    enabledByRight: ['show-notification-bell'],
  },
  {
    key: 'customer-support',
    enabledByRight: ['show-customer-support'],
  },
];
