import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';

import { TranslationModule } from '@s2a-core/ng-core';
import '@s2a-shared-elements/button';
import { Environment } from '@s2a/core';

import { windowProvider, WindowToken } from '../lib/services/window.service';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { CookieService } from './services/cookie.service';

@NgModule({
  declarations: [LanguageSelectorComponent],
  exports: [LanguageSelectorComponent],
  imports: [CommonModule, TranslationModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NgShellModule {
  static forRoot(environment: Environment): ModuleWithProviders<NgShellModule> {
    return {
      ngModule: NgShellModule,
      providers: [
        { provide: WindowToken, useFactory: windowProvider },
        CookieService,
        {
          provide: Environment,
          useValue: environment,
        },
      ],
    };
  }
}
