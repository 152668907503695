import { debounceTime, filter, map, pairwise } from 'rxjs/operators';

import { CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';

import { TimelineEvent, TimelineGroup } from '../../../../models/notification-history.model';

@Component({
  selector: 's2a-virtual-history-item',
  templateUrl: './virtual-history-item.component.html',
  styleUrls: ['./virtual-history-item.component.scss'],
})
export class VirtualHistoryItemComponent implements AfterViewInit {
  @Input() loadingPaginatedData = false;
  @ViewChild(CdkVirtualScrollViewport, { static: true })
  virtualScroll: CdkVirtualScrollViewport;
  @Input() timelineGroups: TimelineGroup[];

  /**
   * Event Emitter to load the next paginated data
   *
   */
  @Output() fetchPaginatedData = new EventEmitter();

  /**
   * Return the link when timeline clicked
   *
   * @returns string
   */
  @Output() timelineClicked = new EventEmitter<TimelineEvent>();

  /**
   * Return the new ReadStatus of the selected item
   *
   * @returns TimelineEvent
   */
  @Output() switchReadStatus = new EventEmitter<TimelineEvent>();

  constructor(
    private scrollDispatcher: ScrollDispatcher,
    private ngZone: NgZone,
  ) {}

  /**
   * @ignore
   */
  onTimelineClick(event: TimelineEvent): void {
    this.timelineClicked.emit(event);
  }

  /**
   * @ignore
   */
  onSwitchReadStatus(update: TimelineEvent): void {
    this.switchReadStatus.emit(update);
  }

  ngAfterViewInit(): void {
    this.scrollDispatcher
      .scrolled()
      .pipe(
        map(() => this.virtualScroll.measureScrollOffset('bottom')),
        pairwise(),
        filter(([y1, y2]) => y2 < y1 && y2 < 1),
        debounceTime(200),
      )
      .subscribe(() => {
        this.ngZone.run(() => {
          this.fetchPaginatedData.emit();
        });
      });
  }
}
