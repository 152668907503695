<div class="header-wrapper" s2aLayout="column" s2aLayoutAlign="start">
  <div class="header-bar" style="width: 100%">
    <div s2aLayout="row" s2aLayoutAlign="space-between start">
      <div translate class="text" s2aFlex>components.notification.title</div>
      <div>
        <button mat-icon-button color="accent" (click)="onFilterClicked()">
          <mat-icon svgIcon="filter"></mat-icon>
        </button>
        <mat-icon *ngIf="filterApplied" class="filter-applied"> fiber_manual_record </mat-icon>
      </div>
    </div>
    <!-- <div class="search-bar">
      <mat-form-field appearance="outline">
        <mat-label translate>global.search</mat-label>
        <input matInput placeholder="Placeholder">
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
    </div> -->
    <div class="toggle" s2aLayout="row" s2aLayoutAlign="space-between start">
      <a class="status-change" (click)="onReadAllNotification()">{{
        'components.notification.mark_all_as_read' | translate
      }}</a>
      <mat-slide-toggle translate (change)="toggleNotification($event)"
        ><span translate>components.notification.show_only_unread</span></mat-slide-toggle
      >
    </div>
  </div>
</div>
