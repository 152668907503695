import { Subject } from 'rxjs';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SideNavGroupItem } from '../../../models/side-nav-group-item.model';
import { ClickedItem, SidenavItem } from '../../../models/sidenav-item.model';

@Component({
  selector: 's2a-pure-sidenav',
  templateUrl: './pure-sidenav.component.html',
  styleUrls: ['./pure-sidenav.component.scss'],
})
export class PureSidenavComponent implements OnInit {
  /**
   * Returns the id of the selected item
   */
  @Output() profileClick = new EventEmitter<void>();

  /**
   * Returns the id of the selected item
   *
   * @returns string
   */
  @Output() itemClick = new EventEmitter<ClickedItem>();

  /**
   * If true, the sidenav is expanded, otherwise collapsed.
   */
  @Input() expanded = false;

  /**
   * The base url to the image assets
   */
  @Input() imgBaseUrl: string;

  /**
   * The name of the currently active app. String will be shown in mobile mode in the bottom nav bar next to the menu button.
   */
  @Input() activeAppName: string;

  /**
   * All items which will be shown in the side menu
   */
  // @Input() items: SidenavItem[];
  @Input() items: SideNavGroupItem[];

  /**
   * App Bell Item which will be shown in the side menu
   */
  @Input() appBell: SidenavItem;

  /**
   * The url to the user's profile image. If not set, the user initials will be shown instead. This will only be shown, if in mobile mode.
   */
  @Input() userImageUrl: string;

  /**
   * The user's initials. Will be only shown, if the userImageUrl is empty. This will only be shown, if in mobile mode.
   */
  @Input() userInitials: string;

  /**
   * The boolean that determines if the navigation bar application visible or not
   */
  @Input() isNavigationMenuVisible = true;

  /**
   * @ignore
   */
  itemClickedSubject: Subject<void>;

  /**
   * @ignore
   */
  isMobile: boolean;

  /**
   * @ignore
   */
  toggleAppBell: boolean;

  /**
   * @ignore
   */
  _imgBaseUrl: string;

  constructor() {}

  /**
   * @ignore
   */
  ngOnInit(): void {
    this._imgBaseUrl = this.imgBaseUrl;
    this.itemClickedSubject = new Subject<void>();
  }

  /**
   * The function is used to detect the platform change to mobile
   *
   * @param isMobile Boolean
   */
  onPlatformChangeHandler(isMobile: boolean): void {
    this.isMobile = isMobile;
  }

  /**
   * @ignore
   */
  onItemClicked(item: ClickedItem): void {
    this.itemClickedSubject.next();
    this.itemClick.emit(item);
  }

  /**
   * @ignore
   */
  onProfileClicked(): void {
    this.profileClick.emit();
  }

  /**
   * @ignore
   */
  onAppBellClicked(isAppBellVisible: boolean): void {
    this.toggleAppBell = isAppBellVisible;
    this.onItemClicked({ key: 'app-bell', route: '' });
  }

  /**
   * The function is used to determine if the app bell is supported or not
   *
   * @returns Boolean
   */
  isAppBellSupported(): boolean {
    return !!this.appBell;
  }
}
