import { JwtHelperService } from '@auth0/angular-jwt';
import { tap } from 'rxjs';

import { AuthService, LOCALSTORAGE_AUTH_TOKEN, UserWithRights } from '@s2a/core';

import { SessionService } from '~ng-shell';

import { RouterService } from './router.service';
import { ShellAuthenticationService } from './shell-authentication.service';

export const setSessionFactory = (
  shellAuthenticationService: ShellAuthenticationService,
  sessionService: SessionService,
  routerService: RouterService,
  authService: AuthService,
): any => {
  const init = () =>
    new Promise<any>((resolve) => {
      authService.isLoggedIn$
        .pipe(
          tap((isLogin: boolean) => {
            if (!isLogin) {
              sessionService.clearSession();
              routerService.redirectToLogin();
            }
          }),
        )
        .subscribe();
      if (authService.hasToken) {
        const jwtHelperService = new JwtHelperService();
        const userId = jwtHelperService.decodeToken(localStorage.getItem(LOCALSTORAGE_AUTH_TOKEN))[
          'custom:userId'
        ] as string;

        shellAuthenticationService.fetchUserWithRights(userId).subscribe((data: UserWithRights) => {
          sessionService.setUserInformation(data);
          resolve(data);
        });
      } else {
        routerService.redirectToLogin();
      }
    });

  return init;
};
