/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { catchError, take, tap } from 'rxjs/operators';

import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Meta } from '@angular/platform-browser';

import { S2ANotificationService, untilDestroyed } from '@s2a-core/ng-core';
import { Logger } from '@s2a-core/ui-logger';
import { EquipmentQueryService, EquipmentService, StoredEquipment } from '@s2a-equipment/core';
import { AuthService, LOCALSTORAGE_USER_SESSION, ShellEvent, Translation, User, UserWithRights } from '@s2a/core';

import { ComponentLoaderService, ComponentMap, SessionService } from '~ng-shell';

import { environment } from '../environments/environment';
// import { HeartBeatService } from './services/heartbeat.service';
import { UserPassswordResetStatus } from './enum/user.enum';
import { WebComponentText } from './models/web-component-text.model';
import { PageLoaderService } from './services/pageLoader.service';
import { RouterService } from './services/router.service';
import { ShellAuthenticationService } from './services/shell-authentication.service';
import { SupportRequestService } from './services/support-request.service';

const logger = Logger('App Shell');

@Component({
  selector: 's2a-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('authPlace', { static: true }) authPlace: ElementRef;
  @ViewChild('changePwdDialog') changePwdDialog: TemplateRef<any>;

  isNavigationMenuVisible = true;
  isAuthenticated = false;
  appBellOpened = false;
  languageRecord: WebComponentText = {};
  suppotRequestDialogueRecord: WebComponentText = {};
  changePwdDialogRef: MatDialogRef<any>;
  lines: StoredEquipment[] = [];
  private untilDestroyed = untilDestroyed();

  constructor(
    public pageLoaderService: PageLoaderService,
    public translateService: Translation,
    public shellAuthenticationService: ShellAuthenticationService,
    private authService: AuthService,
    // private heartBeatService: HeartBeatService,
    private sessionService: SessionService,
    private routerService: RouterService,
    private meta: Meta,
    private dialog: MatDialog,
    private notificationService: S2ANotificationService,
    private componentLoaderService: ComponentLoaderService,
    private equipmentService: EquipmentService,
    private equipmentQueryService: EquipmentQueryService,
    private supportRequestService: SupportRequestService,
  ) {}

  ngOnInit(): void {
    this.getLineEquipments();
    this.subscribeToLanguageChange();
    this.languageRecord = Object.assign({}, this.getTranslatedText());
    this.suppotRequestDialogueRecord = Object.assign({}, this.getSupportRequestTranslatedText());
    this.getUserInfo();
    // Disable indexing non-production environment
    if (!environment.production) {
      this.meta.addTags([
        // To prevent most search engines from indexing a page on your site
        { name: 'robots', content: 'noindex' },
        // To prevent only Google web crawlers from indexing a page
        { name: 'googlebot', content: 'noindex' },
      ]);
    }
    this.authService.isLoggedIn$
      .pipe(
        this.untilDestroyed(),
        tap((isLogin: boolean) => {
          this.isAuthenticated = isLogin;
          if (!isLogin) {
            this.sessionService.clearSession();
            this.routerService.redirectToLogin();
          }
        }),
      )
      .subscribe();

    this.shellAuthenticationService
      .refreshRights()
      .pipe(
        this.untilDestroyed(),
        tap(() => {
          if (!this.isAuthenticated) {
            this.authService.isLoggedIn = true;
          }
          this.isAuthenticated = true;
        }),
        catchError(() => {
          this.authService.isLoggedIn = false;
          this.isAuthenticated = false;

          return 'Failed to refresh user';
        }),
      )
      .subscribe();

    this.authService.hasGlobalRights$.pipe(this.untilDestroyed()).subscribe((globalRights) => {
      let user = this.authService.user;
      user = { ...user, globalRights };
      this.sessionService.setUserInformation(user);
    });

    // this.heartBeatService.heartBeat().pipe(this.untilDestroyed()).subscribe();

    const userSession = sessionStorage.getItem(LOCALSTORAGE_USER_SESSION);
    if (!userSession || Object.keys(userSession).length === 0) {
      this.authService.isLoggedIn = false;
      this.isAuthenticated = false;
    }

    let previousUrl = '';
    window.addEventListener(
      'message',
      (event) => {
        if (event.data.type === ShellEvent.RouteChange) {
          if (event.data.value !== previousUrl) {
            this.routerService.changeRoute(event);
            previousUrl = event.data.value;
          }
        } else if (event.data.type === ShellEvent.UserChange) {
          this.shellAuthenticationService
            .fetchUserWithRights(this.authService.user.userId)
            .pipe(
              this.untilDestroyed(),
              catchError(() => {
                this.authService.isLoggedIn = false;
                this.isAuthenticated = false;

                return 'Failed to fetch user with rights';
              }),
            )
            .subscribe();
        } else if (event.data.type === ShellEvent.PrintModeChange) {
          this.isNavigationMenuVisible = event.data.value;
        }
      },
      false,
    );

    this.componentLoaderService.loadComponent([ComponentMap.ChangePassword]);
  }
  /**
   * The function is used to subscribe to language change observable
   */
  subscribeToLanguageChange(): void {
    this.translateService.onLangChange.pipe(this.untilDestroyed()).subscribe(() => {
      this.languageRecord = Object.assign({}, this.getTranslatedText());
      this.suppotRequestDialogueRecord = Object.assign({}, this.getSupportRequestTranslatedText());
    });
  }

  /**
   * The function is used to toggle app bell side bar
   */
  toggleAppBellHandler(forciblyHide: boolean): void {
    this.appBellOpened = forciblyHide ? !forciblyHide : !this.appBellOpened;
  }

  /**
   * The function is used to show notification on password updation api response
   *
   * @param event CustomEvent
   */
  onPasswordChangedHandler(event: CustomEvent): void {
    const status = event.detail.status;
    if (status) {
      this.changePwdDialogRef.close();
      this.notificationService.success(this.translateService.instant('components.users.change_password.success'));
    } else {
      this.notificationService.error(this.translateService.instant('components.users.change_password.failure'));
    }
  }

  /**
   * The function is used to get the translated text based on translation key
   *
   * @returns WebComponentText
   */
  getTranslatedText(): { [key: string]: string } {
    const languageText: WebComponentText = {};

    languageText.title = this.translateService.instant('components.users.change_password.forced_title');
    languageText.currentPwdText = this.translateService.instant('components.users.change_password.current_password');
    languageText.passwordIsRequiredText = this.translateService.instant(
      'components.users.change_password.password_is_required',
    );
    languageText.passwordMinLengthText = this.translateService.instant(
      'components.users.change_password.password_minlength',
    );
    languageText.passwordMaxLengthText = this.translateService.instant(
      'components.users.change_password.password_maxlength',
    );
    languageText.passwordText = this.translateService.instant('components.users.change_password.password');
    languageText.passwordHintText = this.translateService.instant(
      'components.users.edit_user.user_data.user_infos.password_hint',
    );

    languageText.passwordPatternText = this.translateService.instant(
      'components.users.change_password.password_pattern',
    );

    languageText.passwordConfirmationText = this.translateService.instant(
      'components.users.change_password.password_confirmation',
    );

    languageText.passwordConfirmationIsRequiredText = this.translateService.instant(
      'components.users.change_password.password_confirmation_is_required',
    );

    languageText.paswordConfirmtationMismatchText = this.translateService.instant(
      'components.users.change_password.password_confirmation_mismatch',
    );

    languageText.cancelText = this.translateService.instant('global.cancel');
    languageText.saveText = this.translateService.instant('global.save');

    return languageText;
  }

  /**
   * The method is used to return the translated text based on the language key fo rsupport request dialogue
   *
   * @returns WebComponentText
   */
  getSupportRequestTranslatedText(): { [key: string]: string } {
    const languageText: WebComponentText = {};

    languageText.title = this.translateService.instant('components.customer_support.title');
    languageText.name = this.translateService.instant('components.customer_support.name');
    languageText.email = this.translateService.instant('components.customer_support.email');
    languageText.company = this.translateService.instant('components.customer_support.company');
    languageText.phoneNumber = this.translateService.instant('components.customer_support.phoneNumber');
    languageText.affectedLine = this.translateService.instant('components.customer_support.affectedLine');

    languageText.subject = this.translateService.instant('components.customer_support.subject');

    languageText.description = this.translateService.instant('components.customer_support.description');

    languageText.service = this.translateService.instant('components.customer_support.service');

    languageText.save = this.translateService.instant('components.customer_support.send');
    languageText.requiredField = this.translateService.instant('components.customer_support.field_is_required');
    languageText.emailInvalid = this.translateService.instant('components.customer_support.email_invalid');

    languageText.email_or_phoneprompt = this.translateService.instant(
      'components.customer_support.email_or_phoneprompt',
    );
    languageText.or = this.translateService.instant('components.customer_support.or');

    return languageText;
  }

  /**
   * The function is used to invoke backend to create support ticket in sales force
   *
   * @param payload An object containing the key value pair
   */
  public onCreateTicketClicked(payload: { [key: string]: string }): void {
    this.supportRequestService.createSupportRequestTicket(payload).subscribe({
      next: () => {
        this.notificationService.success(
          this.translateService.instant('components.customer_support.support_ticket_success'),
        );
      },
      error: (error: any) => {
        logger.error(JSON.stringify(error.error));
        this.notificationService.error(
          this.translateService.instant('components.customer_support.support_ticket_failure'),
        );
      },
    });
  }

  /**
   * The function is used to get the logged in user information from API
   */
  private getUserInfo(): void {
    const userId = (this.authService.user || {}).userId;
    this.shellAuthenticationService
      .fetchUserInfo(userId)
      .pipe(this.untilDestroyed(), take(1))
      .subscribe((response: User) => {
        if (response.confirmation && response.confirmation.status === UserPassswordResetStatus.FORCED_PASSWORD_RESET) {
          this.sessionService.setUserInformation(response);
          this.showChangePasswordDialogue(this.authService.user);
        }
      });
  }

  /**
   * The function is used to show the change password dialogue
   *
   * @param user UserWithRights
   */
  private showChangePasswordDialogue(user: UserWithRights): void {
    this.changePwdDialogRef = this.dialog.open(this.changePwdDialog, {
      width: '360px',
      disableClose: true,
      data: {
        user,
      },
    });
  }

  /**
   * Method is used to get the line equipments.
   *
   */
  private getLineEquipments(): void {
    this.equipmentService.ensureCache().pipe(this.untilDestroyed()).subscribe();
    this.equipmentQueryService
      .selectFilter('description', 'line')
      .pipe(this.untilDestroyed())
      .subscribe({
        next: (equipments: StoredEquipment[]) => {
          this.lines = equipments;
        },
        error: () => {
          this.lines = [];
        },
      });
  }
}
