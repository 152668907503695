import { gql } from 'apollo-angular';

export const onCreateHistoriesSubscription = gql`
  subscription onCreateHistoryItem($userId: ID!) {
    onCreateHistoryItem(userId: $userId) {
      timestamp
      userId
      account
      service
      action
      metadata
      resolvedTemplate
    }
  }
`;

export const onUpdateHistorySubscription = gql`
  subscription onUpdateHistory($userId: ID!) {
    onUpdateHistory(userId: $userId) {
      resolvedTemplate
      account
      action
      expiration
      service
      timestamp
      userId
      metadata
      readStatus
    }
  }
`;
