/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivationStart, Router } from '@angular/router';

import { FileService } from '@s2a-core/files';
import { untilDestroyed } from '@s2a-core/ng-core';
import { StoredEquipment } from '@s2a-equipment/core';
import { AuthService, LanguageKey, ShellEvent, ShellListenerEvent, ThemeService, Translation } from '@s2a/core';

import { ComponentLoaderService, ComponentMap, SessionService, showLanguageDialog } from '~ng-shell';

import { environment } from '../../../environments/environment';
import { APPBELL_RIGHT } from '../../config/history.config';
import { getSessionByRight } from '../../config/sessions.config';
import { getKroneEshopUrl } from '../../config/shop.config';
import { Notification } from '../../enum/notification.enum';
import { SideNavGroupItem } from '../../models/side-nav-group-item.model';
import { ClickedItem, SidenavItem } from '../../models/sidenav-item.model';
import { WebComponentText } from '../../models/web-component-text.model';
import { AppRegistryService } from '../../services/app-registry.service';
import { HistoryItemService } from '../../services/history-item.service';
import { PageLoaderService } from '../../services/pageLoader.service';
import { RouterService } from '../../services/router.service';

@Component({
  selector: 's2a-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @Input() isAuthenticated: boolean;
  @Input() isNavigationMenuVisible = true;
  @Output() appBellClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() createTicket: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('languageDialog', { static: true })
  languageDialog: TemplateRef<any>;

  @ViewChild('supportRequestDialogue', { static: true })
  supportRequestDialogue: TemplateRef<any>;
  @Input()
  lines: StoredEquipment[] = [];
  @Input() suppotRequestDialogueRecord: WebComponentText = {};
  userImageUrl: string;
  baseImageUrl: string;
  sidenavItems: SideNavGroupItem[];
  appBellItem: SidenavItem;
  currentLanguageKey: LanguageKey;
  activeAppName = 'welcome';
  supportRequestDialogeRef: MatDialogRef<any>;
  private untilDestroyed = untilDestroyed();

  constructor(
    private authService: AuthService,
    private fileService: FileService,
    private sessionService: SessionService,
    private appRegistryService: AppRegistryService,
    private translateService: Translation,
    private themeService: ThemeService,
    private dialog: MatDialog,
    public pageLoaderService: PageLoaderService,
    private router: Router,
    private routerService: RouterService,
    private historyItemService: HistoryItemService,
    private componentLoaderService: ComponentLoaderService,
  ) {
    this.baseImageUrl = environment.imageUrl;
    this.sidenavItems = this.appRegistryService.sidenavItems;
    this.appBellItem = this.appRegistryService.appBell;
  }

  get sessionsUrl(): string {
    const rights = this.sessionService.getUserInformation().globalRights || [];

    return getSessionByRight(rights as string[]);
  }

  get kronesEShopUrl(): string {
    return getKroneEshopUrl(this.translateService.getCurrentLanguage());
  }

  get userPictureId(): string | undefined {
    return this.sessionService.getUserInformation().picture as string | undefined;
  }

  get userInitials(): string {
    const user = this.sessionService.getUserInformation();

    return (user.firstname || '')
      .charAt(0)
      .concat((user.lastname || '').charAt(0))
      .toUpperCase() as string;
  }

  ngOnInit(): void {
    this.componentLoaderService.loadComponent([ComponentMap.Button]);
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) {
        this.activeAppName = event.snapshot.root?.firstChild.data.appUrl;
      }
    });
    this.loadUserImageUrl();
    this.currentLanguageKey = this.translateService.getCurrentLanguage();
    this.getCount();
  }

  async onItemClicked(item: ClickedItem): Promise<void> {
    switch (item.key) {
      case 'sessions':
        return this.openInNewTab(this.sessionsUrl);
      case 'krones-shop':
        return this.openInNewTab(this.kronesEShopUrl);
      case 'toggle-theme':
        return this.toggleTheme();
      case 'language-selection':
        return this.changeLanguage();
      case 'logout':
        return this.logout();
      case 'app-bell':
        this.componentLoaderService.loadComponent([ComponentMap.DateRangePicker, ComponentMap.BaseReference]);
        this.toggleAppBell();
        break;
      case 'customer-support':
        this.componentLoaderService.loadComponent([ComponentMap.SupportRequest]);
        this.openSupportRequestDialogue();
        break;
      default:
        this.toggleAppBell(true);
        this.activeAppName = item.key;

        return await this.openApp(item);
    }
  }

  async onProfileClicked(): Promise<void> {
    await this.onItemClicked({ key: 'profile', route: 'profile' });
  }

  /**
   * The function is used to close the support request dialogue box with/without payload
   *
   * @param event CustomEvent
   * @param isClose Boolean to close with payload or not
   */
  public onSaveOrCloseClick(event: CustomEvent, isClose?: boolean): void {
    if (this.supportRequestDialogeRef) {
      isClose ? this.supportRequestDialogeRef.close() : this.supportRequestDialogeRef.close(event.detail);
      this.supportRequestDialogeRef = null;
    }
  }

  private getCount(): void {
    if (this.authService.hasGlobalRight(APPBELL_RIGHT)) {
      this.historyItemService
        .getUnseenCount()
        .pipe(this.untilDestroyed())
        .subscribe((count: number) => {
          this.setBadgeCount(count);
        });
    }
  }

  private setBadgeCount(count: number): void {
    const unseenNotificationCount =
      count > Notification.UNSEEN_THRESHOLD ? `+${Notification.UNSEEN_THRESHOLD}` : count === 0 ? '' : count;
    this.appBellItem.badgeCount = unseenNotificationCount;
  }

  private loadUserImageUrl(): void {
    if (this.userPictureId) {
      this.fileService
        .fetchFiles([this.userPictureId])
        .pipe(this.untilDestroyed())
        .subscribe((s2aFiles) => {
          this.userImageUrl = s2aFiles[0].data.url;
        });
    }
  }

  /**
   * The function is used to emit an event to the parent component to toggle the app bell side bar
   *
   * @param forciblyHide Boolean to denote if the panel forcibly should be closed if opened
   */
  private toggleAppBell(forciblyHide: boolean = false): void {
    this.historyItemService
      .updateUnseenHistories()
      .pipe(this.untilDestroyed())
      .subscribe(() => {
        this.setBadgeCount(0);
      });
    this.appBellClicked.emit(forciblyHide);
  }

  private async openApp(item: ClickedItem): Promise<void> {
    const isMigratedRoute = true || (await this.pageLoaderService.isMigratedApp(item.key));
    if (isMigratedRoute) {
      void this.router.navigate([...item.route.split('/')]);
    } else {
      this.routerService.redirectToApp(item.route);
    }
  }

  private logout(): void {
    this.authService.logout();
  }

  private changeLanguage(): void {
    showLanguageDialog(this.dialog, this.languageDialog)
      .pipe(this.untilDestroyed())
      .subscribe((language: LanguageKey) => {
        this.currentLanguageKey = language;
        this.translateService.switchLanguage(language);
        if (language) {
          window.postMessage(
            {
              type: 'language',
              value: language,
            } as ShellListenerEvent,
            '*',
          );
        }
      });
  }

  /**
   * The function is used to open the support request dialogue
   */
  private openSupportRequestDialogue(): void {
    this.supportRequestDialogeRef = this.dialog.open(this.supportRequestDialogue, {
      width: '80vw',
      height: '66vh',
      disableClose: true,
      data: {
        text: this.suppotRequestDialogueRecord,
        userInfo: {
          name: `${this.authService.user.firstname} ${this.authService.user.lastname}`,
          email: this.authService.user.mail,
          company: this.authService.user.account,
          phoneNumber: this.authService.user.phoneNumber,
        },
        lines: this.lines
          .map((value) => {
            const eqVal = {
              label: value.description,
              value: '',
            };
            if (value.properties && value.properties.length) {
              return {
                ...eqVal,
                value:
                  value.properties.reduce((accumulator, currentValue) => {
                    accumulator[currentValue.propertyName] = currentValue.propertyValue;

                    return accumulator;
                  }, {})['kNumber'] || '',
              };
            }

            return eqVal;
          })
          .filter((eq) => eq.value),
      },
    });

    this.supportRequestDialogeRef
      .afterClosed()
      .pipe(this.untilDestroyed())
      .subscribe((data: { [key: string]: string }) => {
        if (data) {
          this.createTicket.emit(data);
        }
      });
  }

  private toggleTheme(): void {
    window.postMessage(
      {
        type: ShellEvent.ThemeChange,
        value: this.themeService.getAlternateTheme(),
      } as ShellListenerEvent,
      '*',
    );
  }

  private openInNewTab(url: string): void {
    window.open(url, '_blank');
  }
}
