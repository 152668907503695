<ng-container *ngIf="openTargetInNewWindow === true; then router; else listItem"></ng-container>

<ng-template #router>
  <a [routerLink]="['/', app]" routerLinkActive="active">
    <ng-container *ngTemplateOutlet="listItem"></ng-container>
  </a>
</ng-template>

<ng-template #listItem>
  <mat-list-item
    translate
    class="enable-touch {{ icon }}"
    [class.flex-end]="!showText"
    (click)="onItemClicked($event, app, route)"
    [matTooltip]="showText ? null : text"
    matTooltipPosition="after"
    matTooltipClass="tooltip"
  >
    <div class="item-container" s2aLayout="row" s2aLayoutAlign="flex-start center">
      <div class="icon-container" s2aLayout="row" s2aLayoutAlign="space-around center">
        <mat-icon [svgIcon]="icon" [class.selected]="isAppBellVisible"></mat-icon>
        <span class="badge" *ngIf="badgeCount && badgeCount > 0">{{ badgeCount }}</span>
      </div>
      <span *ngIf="showText" class="expandable-text">{{ text }}</span>
    </div>
  </mat-list-item>
</ng-template>
