import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ComponentLifecycleManager {
  contentShell: Element;
  shadow: Node;
  content: Node;

  clear(): void {
    this.contentShell = null;
    this.shadow = null;
    this.content = null;
  }

  createComponent(elementRef: ElementRef): void {
    this.contentShell = elementRef.nativeElement;
    if (this.contentShell) {
      this.shadow = this.contentShell.attachShadow({ mode: 'open' });
    }
  }

  addContent(endpoint: string): void {
    const embed = document.createElement('embed');
    embed.setAttribute('width', '100%');
    embed.setAttribute('height', '100%');
    embed.setAttribute('src', endpoint);
    embed.setAttribute('href', endpoint);
    this.content = embed;
  }

  attachComponent(): void {
    this.shadow.appendChild(this.content);
  }

  detachComponent(): void {
    this.shadow.removeChild(this.content);
  }
}
