<ng-container>
  <div class="mat-body">
    <mat-sidenav-container autosize fullscreen (backdropClick)="sidenav.close()">
      <mat-sidenav
        #sidenav
        [ngClass]="{ 'sidenav-wide': expanded }"
        [disableClose]="'true'"
        [mode]="sideNavMode"
        [opened]="sideNavOpened"
        [position]="position"
      >
        <div
          *ngIf="isNavigationMenuVisible"
          class="sidenav-container unselectable s2a-fx-flex-fill"
          s2aLayout="column"
          s2aLayoutAlign="space-between stretch"
        >
          <div class="hide-scrollbar">
            <!-- Header Brand -->
            <div class="sticky">
              <div (click)="toggleSidenavMenu()">
                <ng-container *ngIf="expanded && !isMobile; else smallBrand">
                  <img class="brand-image" [src]="extendedHeaderImage" />
                  <mat-icon class="arrow" svgIcon="double_arrow_left"></mat-icon>
                </ng-container>

                <ng-template #smallBrand>
                  <ng-container *ngIf="!isMobile; else mobileBrand">
                    <img class="brand-image-small" [src]="minimizedHeaderImage" />
                    <mat-icon class="arrow" svgIcon="double_arrow_right"></mat-icon>
                  </ng-container>
                </ng-template>

                <ng-template #mobileBrand>
                  <img class="brand-image" [src]="extendedHeaderImage" />
                  <mat-icon class="arrow" svgIcon="double_arrow_right"></mat-icon>
                </ng-template>

                <hr />
              </div>
            </div>

            <ng-content select="[main]"></ng-content>
          </div>

          <!-- Footer Brand -->
          <mat-icon
            *ngIf="expanded"
            s2aLayout="row"
            class="bottom-brand-image s2a-fx-flex-align-end"
            svgIcon="kronesLogo"
          >
          </mat-icon>
        </div>
      </mat-sidenav>
      <!----- App Bell ----->
      <ng-content select="pure-notification-skeleton"></ng-content>
      <!-- Mobile View -->
      <div class="content-shell" s2aLayout="row" s2aLayoutAlign="center start">
        <div class="s2a-fx-flex-fill" [ngClass]="{ site: isMobile && isNavigationMenuVisible }">
          <ng-content select="pure-sidenav-skeleton-router"></ng-content>
        </div>
        <mat-toolbar
          *ngIf="isNavigationMenuVisible"
          class="mobile-toolbar mat-elevation-z8 show-xs"
          s2aLayout="row"
          s2aLayoutAlign="space-evenly center"
        >
          <a class="profile" (click)="onProfileClicked()">
            <s2a-wc-image-button
              [text]="userImageUrl ? '' : userInitials"
              [imageUrl]="userImageUrl"
            ></s2a-wc-image-button>
          </a>
          <div class="notification-bell" *ngIf="isAppBellSupported">
            <s2a-sidenav-list-item
              [isAppBellVisible]="isAppBellVisible"
              [icon]="'app-bell'"
              [badgeCount]="badgeCount"
              (itemClick)="onAppBellClicked()"
            >
            </s2a-sidenav-list-item>
          </div>
          <span s2aFlex></span>
          <a mat-button class="button-navbar parallelogram" (click)="toggleSidenav()" s2aLayoutAlign="center center">
            <span class="skew-fix" s2aLayoutAlign="center center">
              <div class="app-title">
                {{ activeAppName }}
              </div>
              <mat-icon>menu</mat-icon>
            </span>
          </a>
        </mat-toolbar>
      </div>
    </mat-sidenav-container>
  </div>
</ng-container>
