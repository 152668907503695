import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';

import { Inject, Injectable } from '@angular/core';

import { WindowToken } from '~ng-shell';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  private routeSubject = new Subject<any>();

  constructor(@Inject(WindowToken) private window: Window) {}

  redirectToLogin(): void {
    this.window.location.href = environment.domainUrl.concat(this.generateUri('login'));
  }

  redirectToApp(app: string): void {
    this.window.location.href = environment.domainUrl.concat(app);
  }

  changeRoute(message: MessageEvent): void {
    this.routeSubject.next(message);
  }

  getActiveRoute$(): Observable<MessageEvent> {
    return this.routeSubject.asObservable() as Observable<MessageEvent>;
  }

  private generateUri(prefix: string): string {
    const currentPath = this.window.location.pathname + this.window.location.search;
    if (currentPath === '/') {
      return prefix;
    }

    return `${prefix}?redirect=${encodeURIComponent(currentPath)}`;
  }
}
