<mat-sidenav-container
  class="app-bell-container"
  [ngClass]="{ open: appBellOpened }"
  autosize
  fullscreen
  hasBackdrop="(true)"
  (backdropClick)="onBackdropClick()"
>
  <mat-sidenav #appBell mode="side" position="start" [opened]="appBellOpened" class="app-bell-sidenav">
    <ng-content></ng-content>
  </mat-sidenav>
</mat-sidenav-container>
