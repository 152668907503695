<s2a-sidenav
  [isNavigationMenuVisible]="isNavigationMenuVisible"
  [isAuthenticated]="isAuthenticated"
  (appBellClicked)="toggleAppBellHandler($event)"
  [lines]="lines"
  [suppotRequestDialogueRecord]="suppotRequestDialogueRecord"
  (createTicket)="onCreateTicketClicked($event)"
>
  <router-outlet ngProjectAs="sidenav-router"></router-outlet>
  <s2a-app-bell [appBellOpened]="appBellOpened" ngProjectAs="notification" (appBellClosed)="toggleAppBellHandler(true)">
    <s2a-notification-timeline></s2a-notification-timeline>
  </s2a-app-bell>
</s2a-sidenav>
<ng-template #changePwdDialog let-data>
  <s2a-change-password-wc
    [text]="languageRecord | json"
    (passwordChanged)="onPasswordChangedHandler($any($event))"
    [hideClose]="true"
    [user]="data.user | json"
  ></s2a-change-password-wc>
</ng-template>
