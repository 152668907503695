import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FilterOptions, ReadStatus, TimelineEvent, TimelineGroup } from '../../../models/notification-history.model';

@Component({
  selector: 's2a-pure-timeline',
  templateUrl: './pure-timeline.component.html',
  styleUrls: ['./pure-timeline.component.scss'],
})
export class PureTimelineComponent {
  /**
   * Indicates the load status
   */
  @Input() timelineGroups: TimelineGroup[];

  /**
   * Indicates if the value still gets been fetched load status
   */
  @Input() valueRetrieved = false;

  /**
   * Indicates if paginated data is loading or not
   */
  @Input() loadingPaginatedData = false;

  /**
   * Return the filter name applied by user
   *
   * @returns ReadStatus
   */
  @Output() filteredTimeline = new EventEmitter<ReadStatus>();

  /**
   * Return the filter options
   *
   * @returns FilterOptions
   */
  @Output() applyFilterOptions = new EventEmitter<FilterOptions>();

  /**
   * Return the link when timeline clicked
   *
   * @returns string
   */
  @Output() timelineClicked = new EventEmitter<TimelineEvent>();

  /**
   * Event Emitter to load the next paginated data
   *
   */
  @Output() fetchPaginatedData = new EventEmitter();

  /**
   * Return the new ReadStatus of the selected item
   *
   * @returns TimelineEvent
   */
  @Output() switchReadStatus = new EventEmitter<TimelineEvent>();

  /**
   * Return the new ReadStatus of the selected item
   *
   * @returns TimelineEvent
   */
  @Output() readAllNotification = new EventEmitter<void>();

  /**
   * @ignore
   */
  showFilter = false;

  /**
   * @ignore
   */
  isFilterApplied = false;

  /**
   * @ignore
   */
  filterOptions: FilterOptions;

  /**
   * @ignore
   */
  onToggledFilter(filter: ReadStatus): void {
    this.filteredTimeline.emit(filter);
  }

  /**
   * @ignore
   */
  onTimelineClick(event: TimelineEvent): void {
    this.timelineClicked.emit(event);
  }

  /**
   * @ignore
   */
  onSwitchReadStatus(update: TimelineEvent): void {
    this.switchReadStatus.emit(update);
  }

  /**
   * @ignore
   */
  toggleFilter(): void {
    this.showFilter = !this.showFilter;
  }

  /**
   * @ignore
   */
  onApplyFilter(filterOptions: FilterOptions): void {
    this.filterOptions = filterOptions;
    if (filterOptions.services || filterOptions.date) {
      this.isFilterApplied = true;
      this.applyFilterOptions.emit(filterOptions);
    } else {
      this.isFilterApplied = false;
      this.applyFilterOptions.emit({
        services: undefined,
        date: undefined,
      });
    }
    this.toggleFilter();
  }

  onReadAllNotification(): void {
    this.readAllNotification.emit();
  }

  /**
   * @ignore
   */
  public fetchMore(): void {
    this.fetchPaginatedData.emit();
  }
}
