<ng-container *ngIf="isAuthenticated">
  <s2a-pure-sidenav
    [userImageUrl]="userImageUrl"
    [imgBaseUrl]="baseImageUrl"
    [items]="sidenavItems"
    [appBell]="appBellItem"
    (itemClick)="onItemClicked($event)"
    (profileClick)="onProfileClicked()"
    [activeAppName]="activeAppName"
    [userInitials]="userInitials"
    [isNavigationMenuVisible]="isNavigationMenuVisible"
  >
    <ng-content select="notification" ngProjectAs="pure-notification"></ng-content>
    <ng-content select="sidenav-router" ngProjectAs="pure-sidenav-router"></ng-content>
  </s2a-pure-sidenav>
</ng-container>

<ng-template #languageDialog>
  <s2a-language-selector [selectedLanguageKey]="currentLanguageKey"></s2a-language-selector>
</ng-template>

<ng-template #supportRequestDialogue let-data>
  <s2a-wc-support-request
    (save)="onSaveOrCloseClick($any($event))"
    [text]="data.text"
    [userInfo]="data.userInfo | json"
    [lines]="data.lines | json"
    [services]="data.services | json"
    (close)="onSaveOrCloseClick($any($event), true)"
  ></s2a-wc-support-request>
</ng-template>
