<div class="timeline-wrapper">
  <s2a-pure-timeline
    [valueRetrieved]="valueRetrieved"
    [timelineGroups]="timelineGroups"
    [loadingPaginatedData]="loadingPaginatedData"
    (filteredTimeline)="onFilterTimeline($event)"
    (timelineClicked)="onTimelineClicked($event)"
    (switchReadStatus)="onSwitchReadStatus($event)"
    (applyFilterOptions)="onApplyFilter($event)"
    (fetchPaginatedData)="fetchMore()"
    (readAllNotification)="onReadAllNotification()"
  ></s2a-pure-timeline>
</div>
