<s2a-sidenav-skeleton
  [imgBaseUrl]="_imgBaseUrl"
  [(expanded)]="expanded"
  [userImageUrl]="userImageUrl"
  [userInitials]="userInitials"
  [activeAppName]="activeAppName"
  (profileClick)="onProfileClicked()"
  [itemClickedEvents]="itemClickedSubject.asObservable()"
  [isAppBellSupported]="isAppBellSupported()"
  [isAppBellVisible]="toggleAppBell"
  [isNavigationMenuVisible]="isNavigationMenuVisible"
  [badgeCount]="appBell?.badgeCount"
  (platformChange)="onPlatformChangeHandler($event)"
  (appBellClick)="onAppBellClicked($event)"
>
  <mat-nav-list main>
    <!-- User Circle  -->
    <a class="my-profile" [routerLink]="['/profile']">
      <mat-list-item
        class="user-circle pl-40"
        [matTooltip]="expanded ? null : ('components.platform_header.my_profile' | translate)"
        matTooltipPosition="after"
        matTooltipClass="tooltip"
        (click)="onProfileClicked()"
      >
        <div class="container" s2aLayout="row" s2aLayoutAlign="flex-start center">
          <div class="s2a-flex">
            <s2a-wc-image-button
              [text]="userImageUrl ? '' : userInitials"
              [imageUrl]="userImageUrl"
            ></s2a-wc-image-button>
          </div>
          <span *ngIf="expanded" class="expandable-text s2a-flex" translate>components.platform_header.my_profile</span>
        </div>
      </mat-list-item>
    </a>

    <s2a-sidenav-list-item
      *ngIf="isAppBellSupported() && !isMobile"
      [class.s2a-flex]="true"
      [class.pl-40]="true"
      [class.side-nav]="expanded"
      [showText]="expanded"
      [app]="appBell.id"
      [icon]="appBell.icon"
      [text]="appBell.textKey | translate"
      [badgeCount]="appBell.badgeCount"
      [openTargetInNewWindow]="false"
      (itemClick)="onAppBellClicked(!toggleAppBell)"
    >
    </s2a-sidenav-list-item>

    <ng-container *ngFor="let group of items; let j = index">
      <hr *ngIf="group.items.length > 0" />
      <div *ngIf="group.items.length > 0" class="side-bar-grp-container">
        <span class="groupName">{{ group?.groupName === '' ? '&nbsp;' : group?.groupName }}</span>
        <div fxLayout="column" fxLayoutAlign="center" class="w-100">
          <ng-container *ngFor="let item of group.items; let i = index">
            <s2a-sidenav-list-item
              [showText]="expanded"
              [class.side-nav]="expanded"
              [app]="item.id"
              [route]="item.routePath"
              [icon]="item.icon"
              [text]="!group.displayLocaleText ? item.text : (item.textKey | translate)"
              [openTargetInNewWindow]="item.canOpenInNewWindow"
              (itemClick)="onItemClicked($event)"
            >
            </s2a-sidenav-list-item>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </mat-nav-list>
  <ng-content select="pure-notification" ngProjectAs="pure-notification-skeleton"></ng-content>
  <ng-content select="pure-sidenav-router" ngProjectAs="pure-sidenav-skeleton-router"></ng-content>
</s2a-sidenav-skeleton>
