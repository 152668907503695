<div class="title" translate>{{ title }}</div>

<div class="list">
  <div *ngFor="let language of languages; index as idx">
    <div
      class="list-item"
      [ngClass]="{ selected: language.key === selectedLanguageKey }"
      (click)="onClick(language.key, idx)"
    >
      <div class="flag-container">
        <s2a-wc-image-button [imageUrl]="language.imageUrl"></s2a-wc-image-button>
      </div>
      <span class="lang-name">{{ language.description }}</span>
    </div>
  </div>
</div>
