import { Injectable } from '@angular/core';

import {
  LOCALSTORAGE_ACCESS_TOKEN,
  LOCALSTORAGE_AUTH_TOKEN,
  LOCALSTORAGE_REFRESH_TOKEN,
  LOCALSTORAGE_USER_SESSION,
} from '@s2a/core';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  constructor() {}

  getUserInformation(): any {
    let user = window.sessionStorage.getItem(LOCALSTORAGE_USER_SESSION) || '{}';
    user = JSON.parse(user);

    return user;
  }

  setUserInformation(user: object): void {
    if (user && Object.keys(user).length) {
      window.sessionStorage.setItem(LOCALSTORAGE_USER_SESSION, JSON.stringify(user));
    }
  }

  setSession(session: object): void {
    if (session && Object.keys(session).length) {
      // Common Module Auth service required this
      window.localStorage.setItem(LOCALSTORAGE_AUTH_TOKEN, 'IdToken' in session ? (session['IdToken'] as string) : '');
      window.localStorage.setItem(
        LOCALSTORAGE_ACCESS_TOKEN,
        'AccessToken' in session ? (session['AccessToken'] as string) : '',
      );
      window.localStorage.setItem(
        LOCALSTORAGE_REFRESH_TOKEN,
        'RefreshToken' in session ? (session['RefreshToken'] as string) : '',
      );
      window.sessionStorage.setItem(LOCALSTORAGE_USER_SESSION, JSON.stringify(session));
    }
  }

  clearSession(): void {
    window.localStorage.removeItem(LOCALSTORAGE_AUTH_TOKEN);
    window.localStorage.removeItem(LOCALSTORAGE_ACCESS_TOKEN);
    window.localStorage.removeItem(LOCALSTORAGE_REFRESH_TOKEN);
    window.sessionStorage.removeItem(LOCALSTORAGE_USER_SESSION);
  }

  clearStorage(): void {
    window.localStorage.clear();
  }
}
