import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Translation } from '@s2a/core';

import { ClickedItem } from '../../../models/sidenav-item.model';

@Component({
  selector: 's2a-sidenav-list-item',
  templateUrl: './pure-list-item.component.html',
  styleUrls: ['./pure-list-item.component.scss'],
})
export class PureListItemComponent {
  /**
   * Return the name of the app, if list-item is clicked
   *
   * @returns string
   */
  @Output() itemClick = new EventEmitter<ClickedItem>();

  /**
   * Name of the app, which this list item represents
   */
  @Input() app: string;

  /**
   * Route of app, which this list item represents
   */
  @Input() route: string;

  /**
   * If set to true, the text will be shown next to app-icon. If set to false, only the app-icon will be visible.
   */
  @Input() showText: boolean;

  /**
   * The text, which will be shown next to the icon, in case "showText" is set to true.
   */
  @Input() text: string;

  /**
   * Boolean, if right click should show option to open the app in a new window.
   */
  @Input() openTargetInNewWindow = false;

  /**
   * Unseen notification count.
   */
  @Input() badgeCount: number | string = 0;

  /**
   * Unseen notification count.
   */
  @Input() isAppBellVisible = false;

  /**
   * @ignore
   */
  _icon?: string;

  constructor(private translation: Translation) {}

  /**
   * The name of the icon
   */
  @Input()
  get icon(): string {
    return this._icon;
  }
  set icon(value: string) {
    this._icon = value ?? this.app;
  }

  /**
   * @ignore
   */
  onItemClicked(event: MouseEvent, key: string, route: string): void {
    if (event.ctrlKey || event.metaKey) {
      // skip -- mousewheel or ctrl-click to open in new tab
    } else {
      this.itemClick.emit({ key, route });
    }
  }
}
