import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { S2AHttpClient } from '@s2a-core/ng-core';

@Injectable({
  providedIn: 'root',
})
export class SupportRequestService {
  constructor(private httpClient: S2AHttpClient) {}

  /**
   * The method is used to create support request ticket
   *
   * @returns Observable<void>
   */
  public createSupportRequestTicket(payload: { [key: string]: string }): Observable<void> {
    // Don't alter existing object, object is immutable
    const duplicatePayload = Object.assign({}, payload);
    duplicatePayload.description = `${duplicatePayload.company};${payload.description}`;
    duplicatePayload.caseType = 'Support';
    duplicatePayload.machineStatus = 'Low - Without Affection';
    duplicatePayload.requestType = 'IT/Software';
    duplicatePayload.activity = 'Troubleshooting';
    duplicatePayload.personnelNumber = '';
    duplicatePayload.caseOrigin = 'Share2Act';
    duplicatePayload.service = '';
    delete duplicatePayload.company;

    return this.httpClient.post('/support/ticket', duplicatePayload);
  }
}
