/*
 * Public API Surface of ng-shell
 */

export * from './lib/ng-shell.module';

export * from './lib/components/language-selector/language-selector.component';

export * from './lib/config/application.config';
export * from './lib/config/components.config';

export * from './lib/constants/storage.constant';

export * from './lib/models/platform.models';

export * from './lib/services/session.service';
export * from './lib/services/window.service';
export * from './lib/services/cookie.service';

export * from './lib/guards/authorize.guard';
export * from './lib/utils/language-dialog.util';

export * from './lib/testing/windowStub.service';
export * from './lib/services/component-loader.service';
